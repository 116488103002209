import React from "react"
import { graphql } from "gatsby"
import style from "./blog-post.module.scss"
import Label from "../components/label/label"
import Button from "../components/button/button"
import SEO from "../components/seo/seo"
import { MarkdownNode, MarkdownNodeFrontmatter } from "../components/interface/Markdownnode.interface"

type BlogPostProps = {
  data: MarkdownNode
}

export default function BlogPost<BlogPostProps>({ data }) {
    const post = data.markdownRemark

    return (
        <>
        {/* <div className={`h-40`}
              style={{
                backgroundImage:`url('./../../${post.frontmatter.previewDesktop}')`,
                backgroundSize: 'cover',
                backgroundFilter: 'blur(2px)',
                opacity: '.9'
            }}></div> */}
        <SEO isBlog markdownnode={post}></SEO>
        <div className={'fixed z-10 text-sm'}><Button text="&lt; Back" appearance="secondary" url="/"></Button></div>
        <div className="container mx-auto px-4 md:px-32 pb-32">
            <div className={`${style.blogpost} pt-40`} >
                <h1 className={'mod-underline-left'}>{post.frontmatter.title}</h1>
                <div className="mb-2">
                  { post.frontmatter.tagsDev && post.frontmatter.tagsDev.map((value:any, index:number) => {
                      return <Label appearance="primary" key={index}>{value}</Label>
                  })}
                </div>
                <div>
                { post.frontmatter.tagsUI && post.frontmatter.tagsUI.map((value:any, index:number) => {
                    return <Label appearance="secondary" key={index}>{value}</Label>
                })}
                </div>
                <div className="text-xs uppercase font-bold mt-8">{ data.markdownRemark.frontmatter.subtitle } </div>
                <div className="mt-40"dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
        </div>
        </>
    )
}

export const query = graphql`
query($slug: String!) {
  markdownRemark(fields: { slug: { eq: $slug } }) {
    html
    frontmatter {
      title,
      date,
      summary,
      subtitle,
      tagsDev,
      tagsUI,
      previewDesktop
    }
  }
}
`